import React from "react";

import BounceLoader from "react-spinners/BounceLoader";

export default function Spinner({ full, center, size }) {
  //const full
  //

  let className = "d-inline-block";

  if (full && center) {
    className = "d-flex my-5 py-5 align-items-center justify-content-center";
  }

  if (!full && center) {
    className = `${className} text-center`;
  }

  return (
    <div className={className}>
      <BounceLoader loading={true} size={size || 80} color={"#0d6efd"} />
    </div>
  );
}
