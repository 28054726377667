import { useState } from "react";
import { Link } from "react-router-dom";

import { formatDate } from "../utils.js";

function BookingSatus({ status }) {
  let icon;
  let humanStatus;

  if (status === "assigned") {
    icon = <i className="bi bi-check-circle-fill fs-3 text-success"></i>;
    humanStatus = "Confirmada";
  } else if (status === "cancelled") {
    icon = <i className="bi bi-x-circle-fill fs-3 text-danger"></i>;
    humanStatus = "Rechazada";
  } else {
    icon = <i className="bi bi-hourglass-top fs-3 text-secondary"></i>;
    humanStatus = "Por asignar";
  }

  return (
    <div className="d-flex align-items-center">
      {icon}
      <div className="d-none ms-2 d-md-inline">{humanStatus}</div>
    </div>
  );
}

export default function BookingItem({
  item,
  showUser,
  showBuilding,
  thirdPerson,
  previous,
}) {
  const [isHover, setIsHover] = useState(false);
  if (showBuilding == true) {
    return (
      <div key={item.id} className="row my-2">
        <div className="col-12">
          <Link
            to={``}
            className={`bg-${
              isHover ? "secondary" : "white"
            } border rounded shadow-sm p-2 d-block`}
            style={{
              cursor: "pointer",
              textDecoration: "none",
            }}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
          >
            <div className="row align-items-center">
              <div className="col-3 ">{item.user}</div>
              <div className="col-1 text-center">{item.plate}</div>
              <div className="col-2 text-center">{item.parking}</div>
              <div className="col-2 text-center">
                {item.description.split("[")[0]}
                <br></br>
                {item.description.split("[")[1].replace("]", "")}
              </div>
              <div className="col-2 text-center ">{item.startTime}</div>
              <div className="col-2 text-center ">{item.endTime}</div>
            </div>
          </Link>
        </div>
      </div>
    );
  }
  if (thirdPerson == true) {
    return (
      <div key={item.id} className="row my-2">
        <div className="col-12">
          <div
            className={`bg-${
              isHover ? "light" : "white"
            } border rounded shadow-sm p-2 d-block`}
          >
            <div className="row align-items-center">
              <div className="col-3">
                <Link
                  to={`user/${item.id}`}
                  style={{
                    cursor: "pointer",
                    textDecoration: "none",
                  }}
                  onMouseEnter={() => setIsHover(true)}
                  onMouseLeave={() => setIsHover(false)}
                >
                  {item.name}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div key={item.id} className="row my-2">
      <div className="col-12">
        <Link
          to={`/bookings/${item.id}${previous == true ? "?previous=true" : ""}`}
          className={`bg-${
            isHover ? "light" : "white"
          } border rounded shadow-sm p-2 d-block`}
          style={{
            cursor: "pointer",
            textDecoration: "none",
          }}
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
        >
          <div className="row align-items-center">
            <div className="col-3">
              <div className="fw-semibold">
                {formatDate(item.startTime, "EEEE dd 'de' MMMM")}
              </div>
              <div className="text-muted" style={{ fontSize: ".9em" }}>
                {formatDate(item.startTime, "HH:mm") +
                  " - " +
                  formatDate(item.endTime, "HH:mm")}
              </div>
            </div>

            <div className="col-3">
              {item.building ? item.building.name : ""}

              {item.state === "assigned" && item.parking
                ? ` - ${item.parking.name} ${item.parking.description}`
                : ""}
            </div>

            <div className="col-3">
              {showUser
                ? `${item.plate.user.first_name} ${item.plate.user.last_name} - ${item.plate.plate}`
                : item.plate.plate}
            </div>

            <div className="col-3">
              <BookingSatus status={item.state} />
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}
