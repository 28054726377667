import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { Link } from "react-router-dom";

import { useMutationClient } from "../hooks.js";

export default function SignupPage() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [rut, setRut] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [charge, setcharge] = useState("");

  const query = useQuery("groups");
  const groups =
    (query.data && query.data.result && query.data.result.groups) || [];
  const mutationClient = useMutationClient();

  const mutation = useMutation((data) => mutationClient.post("signup", data));

  let errorMessage;

  if (mutation.isError && mutation.error) {
    errorMessage =
      (mutation.error.response &&
        mutation.error.response.data &&
        mutation.error.response.data.error &&
        mutation.error.response.data.error.message) ||
      null;
  }

  return (
    <div
      className="row d-flex justify-content-center"
      style={{ height: "100vh" }}
    >
      <div className="col-12 col-lg-4 my-5">
        <div className="card shadow-sm mt-5">
          <div className="card-body">
            <div className="text-center mb-4">
              <Link to="/">
                <img src="/Logo-LH.png" width="150" />
              </Link>
            </div>

            <h4 className="text-center mb-3">
              Registro de usuarios <hr />
            </h4>

            {mutation.isSuccess ? (
              <div className="text-center">
                <p className="alert alert-success m-0">
                  <i
                    className="bi bi-check-circle"
                    style={{ fontSize: "4em" }}
                  ></i>
                  <br />
                  Su cuenta ha sido creada y se ha enviado un correo electrónico
                  a la dirección ingresada para terminar su registro y validar
                  su cuenta.
                </p>
              </div>
            ) : (
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  mutation.reset();
                  mutation.mutate({
                    first_name: firstName,
                    last_name: lastName,
                    rut,
                    email,
                    password,
                    passwordConfirmation,
                    charge,
                  });
                }}
              >
                <div className="mb-3">
                  <label className="fw-bold mb-2">Nombre</label>
                  <input
                    className="form-control form-control-lg"
                    type="text"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    disabled={mutation.isLoading}
                  />
                </div>

                <div className="mb-3">
                  <label className="fw-bold mb-2">Apellido</label>
                  <input
                    className="form-control form-control-lg"
                    type="text"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    disabled={mutation.isLoading}
                  />
                </div>

                <div className="mb-3">
                  <label className="fw-bold mb-2">RUT</label>
                  <input
                    className="form-control form-control-lg"
                    type="text"
                    value={rut}
                    onChange={(e) => setRut(e.target.value)}
                    disabled={mutation.isLoading}
                  />
                </div>

                <div className="mb-3">
                  <label className="fw-bold mb-2">
                    Correo electrónico institucional
                  </label>
                  <input
                    className="form-control form-control-lg"
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    disabled={mutation.isLoading}
                  />
                </div>

                <div className="mb-3">
                  <label className="fw-bold mb-2">Contraseña</label>
                  <input
                    className="form-control form-control-lg"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    disabled={mutation.isLoading}
                  />
                </div>

                <div className="mb-3">
                  <label className="fw-bold mb-2">Confirmar contraseña</label>
                  <input
                    className="form-control form-control-lg"
                    type="password"
                    value={passwordConfirmation}
                    onChange={(e) => setPasswordConfirmation(e.target.value)}
                    disabled={mutation.isLoading}
                  />
                </div>
                <div className="mb-3">
                  <label className="fw-bold mb-2">Selecionar cargo</label>
                  <select
                    className="form-control form-control-lg"
                    value={charge}
                    onChange={(e) => setcharge(e.target.value)}
                    disabled={mutation.isLoading}
                  >
                    <option value="">Seleccione un cargo</option>
                    {groups.map((group) => (
                      <option key={group.id} value={group.id}>
                        {group.charge}
                      </option>
                    ))}
                  </select>
                </div>

                {errorMessage ? (
                  <div className="mb-3 text-center text-danger">
                    {errorMessage}
                  </div>
                ) : null}

                <div className="d-grid mb-2">
                  <input
                    type="submit"
                    className="btn btn-primary btn-lg mb-3"
                    value="Crear cuenta"
                    disabled={mutation.isLoading}
                  />

                  <Link to="/login" className="text-center">
                    Iniciar sesión
                  </Link>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
