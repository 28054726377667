import React from "react";
import { useState, forwardRef, useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import { useMutationClient } from "../hooks.js";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.min.css";
import { Calendar } from "react-multi-date-picker";

import {
  addDays,
  getDay,
  getMonth,
  set,
  intervalToDuration,
  formatDuration,
  getHours,
  getMinutes,
  getDate,
  isToday,
  fromUnixTime,
  differenceInSeconds,
} from "date-fns";

import { es } from "date-fns/locale";

function BuildingSelector({ defaultValue, onChange }) {
  const [selected, setSelected] = useState(defaultValue);

  const query = useQuery("buildings/");
  const buildings =
    (query.data && query.data.result && query.data.result.buildings) || [];

  useEffect(() => {
    onChange(selected);
  }, [selected]);

  return (
    <div className="d-flex">
      {buildings.map((item) => (
        <label
          key={item.id}
          className={`btn ${
            selected === item.id ? "btn-primary" : "btn-light border"
          } me-2`}
        >
          <input
            className="form-check-input me-2"
            type="radio"
            name="building-selector"
            value={item.id}
            onChange={() => setSelected(item.id)}
          />

          <span>{item.name}</span>
        </label>
      ))}
    </div>
  );
}
function PlateValidator(plate) {
  var plateTmp = plate.toUpperCase();
  plateTmp = plateTmp.replaceAll("-", "");
  plateTmp = plateTmp.replaceAll(" ", "");
  if (plateTmp.length == 6 || plateTmp.length == 5) {
    return true;
  }
  return false;
}

function PlateSelector({ selectedUserId, defaultValue, onChange, onFormShow }) {
  const mutationClient = useMutationClient();

  const [showForm, setshowForm] = useState(false);
  const [plate, setPlate] = useState("");
  const [currentPlateId, setcurrentPlateId] = useState(defaultValue || "");

  const query = useQuery(
    `users/${selectedUserId ? selectedUserId + "/plates" : "me"}`
  );
  const plates =
    (query.data && query.data.result && query.data.result.plates) || [];

  const mutation = useMutation((data) => mutationClient.post("plate", data), {
    onSuccess: (data) => {
      if (data.status === 200) {
        query.refetch();
        setcurrentPlateId(data.data.result.id);
        setPlate("");
        setshowForm(false);
      }
    },
  });

  useEffect(() => {
    onChange(currentPlateId);
  }, [currentPlateId]);

  useEffect(() => {
    onFormShow(showForm);
  }, [showForm]);

  const errorMessage =
    (mutation.error &&
      mutation.error.response &&
      mutation.error.response.data &&
      mutation.error.response.data.error &&
      mutation.error.response.data.error.message) ||
    null;

  if (showForm) {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col">
            <input
              type="text"
              className="form-control form-control-lg text-uppercase"
              value={plate}
              onChange={(e) => setPlate(e.target.value.toUpperCase())}
              autoFocus={true}
              disabled={mutation.isLoading}
            />
          </div>

          <div className="col-auto">
            <button
              className="btn btn-lg btn-outline-primary"
              onClick={() => {
                mutation.reset();
                mutation.mutate({ plate, UserId: selectedUserId });
              }}
              disabled={mutation.isLoading || !PlateValidator(plate)}
            >
              Guardar
            </button>

            <button
              className="btn btn-lg btn-outline-secondary ms-3"
              onClick={() => {
                setPlate("");
                mutation.reset();
                setshowForm(false);
              }}
            >
              Cancelar
            </button>
          </div>
        </div>

        {errorMessage ? (
          <div className="row">
            <div className="col">
              <div className="text-danger text-center mt-3">{errorMessage}</div>
            </div>
          </div>
        ) : null}
      </React.Fragment>
    );
  }

  return (
    <div className="row align-items-center">
      <div className="col">
        <select
          className="form-select form-select-lg"
          value={currentPlateId}
          onChange={(e) => setcurrentPlateId(parseInt(e.target.value))}
        >
          {plates.length ? (
            <React.Fragment>
              <option value="">Seleccionar patente</option>

              {plates.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.plate}
                </option>
              ))}
            </React.Fragment>
          ) : (
            <option value="">Sin patentes registradas</option>
          )}
        </select>
      </div>

      <div className="col-auto">
        <button className="btn btn-link" onClick={() => setshowForm(true)}>
          Crear patente
        </button>
      </div>
    </div>
  );
}

function UserSelector({ defaultValue, onChange }) {
  const [userId, setUserId] = useState("");

  const query = useQuery("users/me/users");
  const users =
    (query.data && query.data.result && query.data.result.users) || [];

  useEffect(() => {
    onChange(userId);
  }, [userId]);

  return (
    <div className="row align-items-center">
      <div className="col">
        <select
          className="form-select form-select-lg"
          value={userId}
          onChange={(e) =>
            setUserId(e.target.value ? parseInt(e.target.value) : "")
          }
        >
          {users.length ? (
            <React.Fragment>
              <option value="">Reserva personal</option>

              {users.map((item) => (
                <option key={item.id} value={item.id}>
                  {`${item.first_name} ${item.last_name} - ${item.email}`}
                </option>
              ))}
            </React.Fragment>
          ) : (
            <option value="">No tiene usuarios asignados</option>
          )}
        </select>
      </div>
    </div>
  );
}

function MonthSelector({ onChange }) {
  const currentMonth = getMonth(new Date()) + 1;
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);

  const months = [
    { id: 1, name: "Enero" },
    { id: 2, name: "Febrero" },
    { id: 3, name: "Marzo" },
    { id: 4, name: "Abril" },
    { id: 5, name: "Mayo" },
    { id: 6, name: "Junio" },
    { id: 7, name: "Julio" },
    { id: 8, name: "Agosto" },
    { id: 9, name: "Septiembre" },
    { id: 10, name: "Octubre" },
    { id: 11, name: "Noviembre" },
    { id: 12, name: "Diciembre" },
  ];

  useEffect(() => {
    onChange(selectedMonth);
  }, [selectedMonth]);

  return (
    <div className="row align-items-center">
      <div className="col">
        <select
          className="form-select form-select-lg"
          value={selectedMonth}
          onChange={(e) => setSelectedMonth(parseInt(e.target.value))}
        >
          <option value="">Seleccione un mes</option>

          {months
            .filter((x) => currentMonth <= x.id)
            .map((item) => (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
        </select>
      </div>
    </div>
  );
}

function WeekdaysPicker({ onChange }) {
  const [selectedDays, setSelectedDays] = useState([]);

  const days = [
    [0, "Lu"],
    [1, "Ma"],
    [2, "Mi"],
    [3, "Ju"],
    [4, "Vi"],
    [5, "Sa"],
    [6, "Do"],
  ];

  const styles = {
    height: "40px",
    width: "40px",
    marginRight: ".5em",
    cursor: "pointer",
  };

  useEffect(() => {
    onChange(selectedDays);
  }, [selectedDays]);

  return (
    <div className="d-flex">
      {days.map((item) => (
        <div
          key={item[1]}
          className={`border p-2 shadow-sm rounded-circle d-flex align-items-center justify-content-center ${
            selectedDays.includes(item[0])
              ? "bg-primary text-white"
              : "bg-light"
          }`}
          style={styles}
          onClick={() => {
            setSelectedDays((prev) => {
              if (prev.includes(item[0])) {
                return prev.filter((x) => x !== item[0]);
              } else {
                return [...prev, item[0]];
              }
            });
          }}
        >
          {item[1]}
        </div>
      ))}
    </div>
  );
}

const DateTimeInput = forwardRef(
  ({ value, disabled, onClick, onChange }, ref) => (
    <input
      className="form-control form-control-lg"
      value={value}
      onChange={onChange}
      onClick={onClick}
      ref={ref}
      disabled={disabled}
    />
  )
);

export default function BookingForm({ currentUser, save, error, isLoading }) {
  const [SelectMultiDay, setSelectMultiDay] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [plateId, setPlateId] = useState(null);
  const [buildingId, setBuildingId] = useState(null);
  const [userId, setUserId] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedWeekdays, setSelectedWeekdays] = useState([]);
  const directAssignment =
    (currentUser && currentUser.charge && currentUser.charge.directAsign) ||
    false;
  var isValid = startDate && endDate && buildingId && plateId !== "";

  const minDate = new Date();
  const maxDate =
    getDay(minDate) === 5 ? addDays(minDate, 3) : addDays(minDate, 2);
  useEffect(() => {
    if (startDate && endDate) {
      setEndDate((prev) => {
        if (prev) {
          return set(prev, { date: getDate(startDate) });
        } else {
          return set(startDate, { date: getDate(startDate) });
        }
      });
    }
  }, [startDate]);

  useEffect(() => {
    if (userId) {
      setStartDate(() => {
        const dateToSet = addDays(new Date(), 1);

        const dateInSeconds = Math.round(dateToSet.getTime() / 1000);
        const timeIntervalInSeconds = 15 * 60;

        return fromUnixTime(
          Math.ceil(dateInSeconds / timeIntervalInSeconds) *
            timeIntervalInSeconds
        );
      });
      setEndDate(null);
    } else {
      setStartDate(null);
      setEndDate(null);
    }
  }, [userId]);

  let duration;
  let durationWarning;

  if (startDate !== null && endDate !== null) {
    if (endDate < startDate) {
      durationWarning = "La hora de término debe ser mayor a la hora de inicio";
      duration = 0;
    }
    if (differenceInSeconds(endDate, startDate) === 0) {
      durationWarning = "La hora de término debe ser mayor a la hora de inicio";
    } else {
      duration = intervalToDuration({ start: startDate, end: endDate });
    }
    if (duration && duration.hours < 6 && !userId && !directAssignment) {
      durationWarning =
        "Estimado/a, se recomienda que la reserva sea de mínimo 6 horas, ya que un tiempo menor implica una priorización más baja en su solicitud";
    }
  }

  function isNotSunday(date) {
    const day = getDay(date);
    return day !== 0;
  }

  return (
    <div className="card shadow-sm">
      <div className="card-body">
        {currentUser && currentUser.third_party_bookings ? (
          <div className="mb-3">
            <label className="fw-bold mb-2">Seleccionar usuario</label>

            <UserSelector
              currentUser={currentUser}
              defaultValue={userId}
              onChange={(UserId) => setUserId(UserId)}
            />
          </div>
        ) : null}

        {userId ? (
          <React.Fragment>
            <div className="mb-3">
              <div className="alert alert-info text-center">
                Las reservas para terceros son creadas para los dias indicados y
                repetidas en el mes seleccionado
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-3"> </div>
              <div className="col-6 align-self-center">
                <Calendar
                  weekDays={["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sa"]}
                  months={[
                    "Enero",
                    "Febrero",
                    "Marzo",
                    "Abril",
                    "Mayo",
                    "Junio",
                    "Julio",
                    "Agosto",
                    "Septiembre",
                    "Octubre",
                    "Noviembre",
                    "Diciembre",
                  ]}
                  multiple={true}
                  value={SelectMultiDay}
                  onChange={setSelectMultiDay}
                  minDate={minDate}
                  format="MMMM DD YYYY"
                  mapDays={({ date }) => {
                    let props = {};
                    let isSunday = [0].includes(date.weekDay.index);
                    if (isSunday) {
                      props.disabled = true;
                    }
                    return props;
                  }}
                />
              </div>
              <div className="col-3"> </div>
            </div>
          </React.Fragment>
        ) : (
          <div className="row mb-3">
            <div className="col-12">
              <label className="fw-bold mb-2">Día de la reserva</label>

              <DatePicker
                selected={startDate}
                onChange={(date) =>
                  setStartDate((prev) => {
                    let dateToSet = prev
                      ? date
                      : set(date, { hours: 8, minutes: 0 });

                    // If date chosen is today, set time to the closest future 15 minute slot
                    if (isToday(dateToSet)) {
                      const dateInSeconds = Math.round(
                        new Date().getTime() / 1000
                      );
                      const timeIntervalInSeconds = 15 * 60;
                      return fromUnixTime(
                        Math.ceil(dateInSeconds / timeIntervalInSeconds) *
                          timeIntervalInSeconds
                      );
                    }

                    return dateToSet;
                  })
                }
                minDate={minDate}
                maxDate={maxDate}
                calendarStartDay={1}
                filterDate={isNotSunday}
                dateFormat="dd/MM/yyyy"
                customInput={<DateTimeInput />}
              />
            </div>
          </div>
        )}

        <div className="row mb-3">
          <div className="col-6">
            <label className="fw-bold mb-2">Hora de inicio</label>

            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              showTimeSelect
              showTimeSelectOnly
              minTime={
                isToday(startDate)
                  ? minDate
                  : set(minDate, { hours: 0, minutes: 0 })
              }
              maxTime={set(minDate, { minutes: 59, hours: 23 })}
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="HH:mm"
              timeFormat="HH:mm"
              customInput={<DateTimeInput />}
              disabled={!startDate}
            />
          </div>

          <div className="col-6">
            <label className="fw-bold mb-2">Hora de término</label>

            <DatePicker
              selected={endDate}
              onChange={(date) => {
                const hours = getHours(date);
                const minutes = getMinutes(date);
                setEndDate(set(startDate, { hours, minutes }));
              }}
              showTimeSelect
              showTimeSelectOnly
              minTime={startDate}
              maxTime={set(startDate, { minutes: 59, hours: 23 })}
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="HH:mm"
              timeFormat="HH:mm"
              customInput={<DateTimeInput />}
              disabled={!startDate}
            />
          </div>
        </div>

        {duration ? (
          <div className="mb-3">
            <div className="bg-light border rounded p-2 text-center">
              {formatDuration(duration, { locale: es })}
            </div>
          </div>
        ) : null}

        {durationWarning ? (
          <div className="mb-3">
            <div className="alert alert-warning text-center">
              {durationWarning}
            </div>
          </div>
        ) : null}

        <div className="mb-3">
          <label className="fw-bold mb-2">Seleccionar edificio</label>
          <BuildingSelector
            defaultValue={buildingId}
            onChange={(value) => setBuildingId(value)}
          />
        </div>

        <div className="mb-3">
          <label className="fw-bold mb-2">Patente</label>

          <PlateSelector
            selectedUserId={userId}
            defaultValue={plateId}
            onChange={(value) => setPlateId(value || "")}
            onFormShow={() => {}}
          />
        </div>

        <div className="mb-1 d-grid">
          <button
            className="btn btn-lg btn-success"
            onClick={() => {
              save({
                building_id: buildingId,
                plate_id: plateId,
                startTime: startDate,
                endTime: endDate,
                user_id: userId,
                multiDay: SelectMultiDay,
                //weekDays: selectedWeekdays
                //month: selectedMonth
              });
            }}
            disabled={!isValid || isLoading || endDate < startDate}
          >
            Solicitar reserva
          </button>
        </div>

        {error ? (
          <div className="mt-3">
            <p className="m-0 alert alert-danger text-center">{error}</p>
          </div>
        ) : null}
      </div>
    </div>
  );
}
