import * as React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import axios from "axios";

import BaseLayout from "./app/layout/BaseLayout.js";
import AppLayout from "./app/layout/AppLayout.js";

import LoginPage from "./app/login/LoginPage.js";
import PasswordRecoverPage from "./app/login/PasswordRecoverPage.js";
import PasswordResetPage from "./app/login/PasswordResetPage.js";
import SignupPage from "./app/login/SignupPage.js";
import VerifyPage from "./app/login/VerifyPage.js";
import HomePage from "./app/home/HomePage.js";
import UserPage from "./app/user/UserDetailPage.js";

import NewBookingPage from "./app/bookings/NewBookingPage.js";
import BookingDetailPage from "./app/bookings/BookingDetailPage.js";
import HomePagePrevious from "./app/home/HomePagePrevious.js";

import { useAppReducer, TOKEN_KEY } from "./app.reducer.js";

export const AppContext = React.createContext({});

export const defaultQueryFn = async ({ queryKey }) => {
  const token = localStorage.getItem(TOKEN_KEY);

  let pagination = null;
  let params = {};
  let headers = {};

  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  const res = await axios.get(`${window.config.API_URL}${queryKey[0]}`, {
    params,
    headers,
  });

  //if( res.headers['x-total-pages'] ){
  //pagination = {
  //page: parseInt(res.headers['x-page']),
  //limit: parseInt(res.headers['x-limit']),
  //totalItems: parseInt(res.headers['x-total-items']),
  //totalPages: parseInt(res.headers['x-total-pages'])
  //}
  //}

  //return {
  //result: res.data && res.data.result,
  //pagination
  //};

  return {
    result: (res.data && res.data.result) || res.data,
    pagination,
  };
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: defaultQueryFn,
    },
  },
});

function UnauthorizedRoutes() {
  return (
    <Routes>
      <Route element={<BaseLayout />}>
        <Route
          path="/"
          element={
            <React.Fragment>
              <Navigate to="/login" />
            </React.Fragment>
          }
        />

        <Route path="/login" element={<LoginPage />} />
        <Route path="/signup" element={<SignupPage />} />
        <Route path="/verify" element={<VerifyPage />} />
        <Route path="/password/recover" element={<PasswordRecoverPage />} />
        <Route path="/password/reset" element={<PasswordResetPage />} />

        <Route
          path="*"
          element={
            <React.Fragment>
              <Navigate to="/login" />
            </React.Fragment>
          }
        />
      </Route>
    </Routes>
  );
}

function AuthorizedRoutes() {
  return (
    <Routes>
      <Route element={<AppLayout />}>
        <Route path="" element={<HomePage />} />

        <Route path="bookings">
          <Route path="new" element={<NewBookingPage />} />
          <Route path=":BookingId" element={<BookingDetailPage />} />
          <Route path="previous" element={<HomePagePrevious />} />
        </Route>
        <Route path="user">
          <Route path=":UserId" element={<UserPage />} />
        </Route>

        <Route
          path="*"
          element={
            <React.Fragment>
              <Navigate to="/" />
            </React.Fragment>
          }
        />
      </Route>
    </Routes>
  );
}

export default function App() {
  const reducer = useAppReducer();

  return (
    <QueryClientProvider client={queryClient}>
      {
        //<ReactQueryDevtools initialIsOpen={false} />
      }

      <AppContext.Provider value={reducer}>
        {reducer.state.isLoggedIn ? (
          <AuthorizedRoutes />
        ) : (
          <UnauthorizedRoutes />
        )}
      </AppContext.Provider>
    </QueryClientProvider>
  );
}
