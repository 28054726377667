import { useContext, useEffect } from "react";
import { Link, Outlet } from "react-router-dom";
import { DropdownButton } from "../shared/Buttons.js";
import { AppContext } from "../../App.js";
import { useQuery } from "react-query";

export function AccountButton({ user, isLoading }) {
  const { actions } = useContext(AppContext);
  return (
    <DropdownButton
      label={user ? user.first_name + " " + user.last_name : "Mi cuenta"}
      disabled={isLoading}
    >
      {/* <li>
        <a className="dropdown-item" href="#">
          Mi perfil
        </a>
      </li>

      
      <li>
      <a className="dropdown-item" href="#">
      Reportar problema
      </a>
      </li>
      
      <li>
      <hr className="dropdown-divider" />
    </li> */}
      <li>
        <a className="dropdown-item" href="/bookings/previous">
          Todas las Reserva
        </a>
      </li>
      <li>
        <a className="dropdown-item" href="/bookings/new">
          Nueva Reserva
        </a>
      </li>

      <li>
        <a
          className="dropdown-item"
          style={{ cursor: "pointer" }}
          onClick={(e) => {
            e.preventDefault();
            actions.signOut();
          }}
        >
          Cerrar sesión
        </a>
      </li>
    </DropdownButton>
  );
}

function useCurrentUser(enabled) {
  const { data, isLoading } = useQuery("users/me", { enabled });

  return {
    user: data && data.result && data.result.user,
    isLoading,
  };
}

export function Header() {
  const { state, actions } = useContext(AppContext);
  const { user, isLoading } = useCurrentUser(!state.currentUser);

  useEffect(() => {
    if (!state.currentUser && user) {
      actions.setCurrentUser(user);
    }
  }, [user, state.currentUser]);

  return (
    <nav className="navbar navbar-expand-lg bg-white border rounded shadow-sm">
      <div className="container-fluid">
        <Link className="navbar-brand" to="/app">
          <img src="/Logo-LH.png" width="150" />
          Reserva de estacionamientos
        </Link>

        <div className="d-flex align-items-center">
          <AccountButton isLoading={isLoading} user={user} />
        </div>
      </div>
    </nav>
  );
}

export function EmptyDataList({ message }) {
  return <div className="text-center text-muted py-5 my-5 fs-5">{message}</div>;
}

export default function BaseLayout() {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12 my-3">
          <Header />
        </div>
      </div>

      <Outlet />
    </div>
  );
}
