import { useState, useEffect, useRef } from "react";

export function DropdownButton({ label, disabled, children }) {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);
  const wrapperRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      function handleClick(e) {
        if (!wrapperRef.current.contains(e.target)) {
          setIsOpen(false);
        }
      }

      document.addEventListener("click", handleClick);

      return () => {
        document.removeEventListener("click", handleClick);
      };
    }
  }, [isOpen]);

  return (
    <div className="dropdown" ref={wrapperRef}>
      <button
        className={`btn btn-outline-secondary dropdown-toggle ${
          isOpen ? "show" : ""
        }`}
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded={isOpen}
        onClick={() => setIsOpen((prev) => !prev)}
        disabled={disabled}
      >
        {label}
      </button>

      {children ? (
        <ul
          className={`dropdown-menu dropdown-menu-dark ${isOpen ? "show" : ""}`}
          aria-labelledby="dropdownMenuButton1"
          ref={menuRef}
        >
          {children}
        </ul>
      ) : null}
    </div>
  );
}
