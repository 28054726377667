import React, { useContext } from "react";
import { AppContext } from "../../App.js";
import { useMutation, useQuery } from "react-query";

import { useMutationClient } from "../hooks.js";

import Breadcrumb from "../shared/Breadcrumb.js";
import BookingForm from "./BookingForm.js";

function LimitHour() {
  const settings = useQuery(`settings`);
  return (
    (settings.data &&
      settings.data.result &&
      settings.data.result.limit_hours) ||
    "17:00"
  );
}

export default function NewBookingPage() {
  const { state } = useContext(AppContext);

  const mutationClient = useMutationClient();
  const mutation = useMutation((data) => mutationClient.post("bookings", data));
  const limithours = LimitHour();

  const createdBooking =
    (mutation.isSuccess && mutation.data && mutation.data.data) || null;

  const errorMessage =
    mutation.error &&
    mutation.error.response &&
    mutation.error.response.data &&
    mutation.error.response.data.error.message;

  return (
    <div>
      <div className="row mt-3">
        <div className="d-flex justify-content-between">
          <Breadcrumb
            items={[
              { label: "Mis reservas", to: "/" },
              { label: "Nueva reserva" },
            ]}
          />
        </div>
      </div>

      <div className="row mt-3 justify-content-center">
        <div className="col-12 col-md-6">
          {mutation.isSuccess && createdBooking ? (
            <div className="card">
              <div className="card-body">
                <div className="text-center">
                  <div className="my-4">
                    {createdBooking.state === "assigned" ? (
                      <i
                        className="bi bi-check-circle text-success"
                        style={{ fontSize: "4em" }}
                      ></i>
                    ) : (
                      <i
                        className="bi bi-hourglass-top text-secondary"
                        style={{ fontSize: "4em" }}
                      ></i>
                    )}
                  </div>

                  <div className="px-4">
                    <h4 style={{ fontSize: "2em", fontWeight: "bold" }}>
                      {createdBooking.id.length > 1 ? (
                        <di>Solicitud de reserva</di>
                      ) : (
                        <div> # {createdBooking.id}</div>
                      )}
                    </h4>

                    {createdBooking.state === "assigned" ? (
                      createdBooking.id.length > 1 ? (
                        <p>
                          Tu solicitud ha sido confirmada,en el edificio{" "}
                          <strong>{createdBooking.building.name}</strong>
                        </p>
                      ) : (
                        <p>
                          Tu solicitud ha sido confirmada y cuenta con el
                          estacionamiento{" "}
                          <strong>{createdBooking.parking.name}</strong> en el
                          edificio{" "}
                          <strong>{createdBooking.building.name}</strong>{" "}
                          ubicado en{" "}
                          <strong>{createdBooking.parking.description}</strong>
                        </p>
                      )
                    ) : (
                      <p>
                        Tu solicitud para estacionamiento ha sido enviada
                        correctamente y se encuentra pendiente de confirmación.
                        <br /> <br />
                        El proceso de asignación se realiza las {limithours}, en
                        el cual su reserva será confirmada o rechazada según la
                        disponibilidad de estacionamientos.
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <BookingForm
              currentUser={state.currentUser}
              save={(data) => {
                mutation.reset();
                mutation.mutate(data);
              }}
              error={errorMessage}
              isLoading={mutation.isLoading}
            />
          )}
        </div>
      </div>
    </div>
  );
}
