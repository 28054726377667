import { useContext } from "react";
import { AppContext } from "../App.js";
import axios from "axios";

export function useMutationClient() {
  const { state } = useContext(AppContext);

  let headers = {};

  if (state.token) {
    headers["Authorization"] = `Bearer ${state.token}`;
  }

  return axios.create({
    baseURL: window.config.API_URL,
    headers,
  });
}
