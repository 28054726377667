import React from "react";

import { EmptyDataList } from "../layout/AppLayout.js";
import BookingItem from "../bookings/BookingItem.js";
import Spinner from "../shared/Spinner.js";
import { formatISO, startOfDay } from "date-fns";
import { useQuery } from "react-query";

function BookingsList({ bookings, showUser, showBuilding, thirdPerson }) {
  return (
    <React.Fragment>
      <div className="col-12 mt-3">
        <div
          className="row align-items-center text-muted text-uppercase"
          style={{ fontSize: ".9em" }}
        >
          <div className="col-3">
            <span className="p-2">Fecha</span>
          </div>

          <div className="col-3">
            <span className="p-2">Edificio</span>
          </div>

          <div className="col-3">
            <span className="p-2">Patente</span>
          </div>

          <div className="col-3">
            <span className="p-2">Estado</span>
          </div>
        </div>
      </div>
      {bookings.map((item) => (
        <BookingItem
          key={item.id}
          showUser={showUser}
          item={item}
          showBuilding={showBuilding}
          thirdPerson={thirdPerson}
          previous={true}
        />
      ))}
    </React.Fragment>
  );
}

function PersonalBookingsList({ start_date }) {
  const query = useQuery(`bookings?startTime=${start_date}`);
  const bookings =
    (query.data && query.data.result && query.data.result.bookings) || [];

  return (
    <React.Fragment>
      {query.isLoading ? (
        <Spinner full center />
      ) : !bookings.length ? (
        <EmptyDataList message="No tienes reservas por asignar, confirmadas o rechazadas" />
      ) : (
        <BookingsList
          bookings={bookings}
          showBuilding={false}
          thirdPerson={false}
        />
      )}
    </React.Fragment>
  );
}

export default function HomePagePrevious() {
  const start_date = formatISO(startOfDay(new Date(-1)));
  return (
    <div>
      <PersonalBookingsList start_date={start_date} />
    </div>
  );
}
