import { utcToZonedTime, format, toDate } from "date-fns-tz";
import { es } from "date-fns/locale";

export const TIME_ZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;

export function formatDate(date, formatString = "dd/MM/yyyy HH:mm") {
  if (date === null || date === undefined) return;

  if (date.toUpperCase().endsWith("Z")) {
    return format(utcToZonedTime(date, TIME_ZONE), formatString, {
      locale: es,
    });
  } else {
    return format(utcToZonedTime(date + "Z", TIME_ZONE), formatString, {
      locale: es,
    });
  }
}
