import { Link, useNavigate } from "react-router-dom";

export default function Breadcrumb({ items = [] }) {
  return (
    <nav style={{ "--bs-breadcrumb-divider": "'>'" }} aria-label="breadcrumb">
      <ol className="breadcrumb">
        {items.map((item, idx) =>
          items.length - 1 === idx ? (
            <li
              key={idx}
              className="breadcrumb-item active fs-md-5"
              aria-current="page"
            >
              {item.label}
            </li>
          ) : (
            <li key={idx} className="breadcrumb-item">
              <Link className="fs-md-5" to={item.to ? item.to : ""}>
                {item.label}
              </Link>
            </li>
          )
        )}
      </ol>
    </nav>
  );
}
