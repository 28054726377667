import { useParams, useNavigate } from "react-router-dom";
import { format, parseISO } from "date-fns";
import { useMutation, useQuery } from "react-query";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import Breadcrumb from "../shared/Breadcrumb.js";
import { useMutationClient } from "../hooks.js";
import Spinner from "../shared/Spinner.js";

import { formatDate } from "../utils.js";
import { AppContext } from "../../App.js";
import React, { useContext } from "react";
import { Link, useSearchParams } from "react-router-dom";

const MySwal = withReactContent(Swal);

export default function BookingDetailPage() {
  const params = useParams();
  const navigate = useNavigate();
  const mutationClient = useMutationClient();
  const { state } = useContext(AppContext);

  const query = useQuery(`bookings/${params.BookingId}`);
  const currentBooking = query.data && query.data.result;

  let bookingStatus = "";
  let iconClass = "";
  let stateConfirm = "check-in";
  const [searchParams] = useSearchParams();
  const previous = searchParams.get("previous");

  if (currentBooking) {
    switch (currentBooking.state) {
      case "pending":
        bookingStatus = "Por asignar";
        iconClass = "bi-hourglass-top text-secondary";
        break;

      case "assigned":
        bookingStatus = "Confirmada";
        iconClass = "bi-check-circle-fill text-success";
        break;

      case "cancelled":
        bookingStatus = "Rechazada";
        iconClass = "bi-x-circle-fill text-danger";
        break;
    }
  }

  const deleteBookingMutation = useMutation(
    () => mutationClient.delete(`bookings/${params.BookingId}`),
    {
      onSuccess: (data) => {
        if (data.status === 200) {
          MySwal.fire(
            "¡Reserva cancelada!",
            "Su reserva ha sido cancelada y el cupo ha sido liberado",
            "success"
          );

          navigate("/");
        }
      },
    }
  );

  const confirmBooking = useMutation(
    () =>
      mutationClient.post(`bookings/${params.BookingId}/confirm`, {
        state: stateConfirm,
      }),
    {
      onSuccess: (data) => {
        if (data.status === 200) {
          MySwal.fire("¡" + stateConfirm + " el confirmado!", "", "success");
          navigate("/");
        }
      },
      onError: (error) => {
        MySwal.fire(
          "¡Error al confirmar el " + stateConfirm + "!",
          "",
          "error"
        );
      },
    }
  );

  function cancelBooking() {
    MySwal.fire({
      title: `¿Está seguro que desea cancelar esta reserva?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, cancelar",
      cancelButtonText: "Volver",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteBookingMutation.mutate();
      }
    });
  }

  return (
    <div>
      <div className="row mt-3">
        <div className="d-flex justify-content-between">
          <Breadcrumb
            items={[
              {
                label: "Mis reservas",
                to: previous == "true" ? "/bookings/previous" : "/",
              },
              {
                label: `Detalle de reserva ${
                  currentBooking ? "#" + currentBooking.id : ""
                }`,
              },
            ]}
          />

          <div>
            {currentBooking && currentBooking.state !== "cancelled" ? (
              <button
                className="btn btn-danger"
                to="bookings/new"
                onClick={() => cancelBooking()}
                disabled={
                  deleteBookingMutation.isLoading ||
                  currentBooking.arrivalTime != null
                }
              >
                {deleteBookingMutation.isLoading
                  ? "Cancelando reserva..."
                  : "Cancelar reserva"}
              </button>
            ) : null}
          </div>
        </div>
      </div>

      <div className="row justify-content-center mt-3">
        {query.isLoading ? (
          <Spinner full center />
        ) : (
          <div className="col-6">
            {currentBooking ? (
              <div className="card shadow-sm">
                <div className="card-header">
                  Detalle de reserva #{currentBooking.id}
                </div>

                <div className="card-body">
                  <div className="row mb-3">
                    <div className="col">
                      <label className="fw-bold">Fecha de inicio</label>
                      <p className="m-0">
                        {formatDate(
                          currentBooking.startTime,
                          "dd/MM/yyyy HH:mm"
                        )}
                      </p>
                    </div>

                    <div className="col">
                      <label className="fw-bold">Fecha de término</label>
                      <p className="m-0">
                        {formatDate(currentBooking.endTime, "dd/MM/yyyy HH:mm")}
                      </p>
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="fw-bold">Estado</label>
                    <p className="m-0">
                      <i className={`bi ${iconClass} me-2`}></i>
                      {bookingStatus}
                    </p>
                  </div>

                  <div className="mb-3">
                    <label className="fw-bold">Estacionamiento asignado</label>
                    <p className="m-0">
                      {currentBooking.building
                        ? currentBooking.building.name
                        : ""}

                      {currentBooking.state === "assigned"
                        ? ` - Estacionamiento ${currentBooking.parking.name} ${currentBooking.parking.description}`
                        : " - Sin asignar"}
                    </p>
                  </div>

                  <div className="mb-3">
                    <label className="fw-bold">Patente</label>
                    <p className="m-0">{currentBooking.plate.plate}</p>
                  </div>

                  <div className="row mb-3">
                    <div className="col">
                      {currentBooking.state !==
                      "assigned" ? null : currentBooking.arrivalTime == null &&
                        state.currentUser.id == currentBooking.plate.user.id ? (
                        <div className="col">
                          <p className="m-0">
                            <button
                              className="btn btn-primary"
                              onClick={() => {
                                stateConfirm = "check-in";
                                confirmBooking.mutate();
                              }}
                            >
                              Check-in
                            </button>
                          </p>
                        </div>
                      ) : (
                        <div>
                          <label className="fw-bold">Check-in</label>
                          <p className="m-0">
                            {currentBooking.arrivalTime == null
                              ? "No registrado"
                              : formatDate(
                                  currentBooking.arrivalTime,
                                  "dd/MM/yyyy HH:mm:ss"
                                )}
                          </p>
                        </div>
                      )}
                    </div>
                    <div className="col">
                      {currentBooking.state !==
                      "assigned" ? null : currentBooking.exitTime == null &&
                        state.currentUser.id == currentBooking.plate.user.id ? (
                        <div className="col">
                          <p className="m-0">
                            <button
                              className="btn btn-primary"
                              disabled={currentBooking.arrivalTime == null}
                              onClick={() => {
                                stateConfirm = "check-out";
                                confirmBooking.mutate();
                              }}
                            >
                              Check-out
                            </button>
                          </p>
                        </div>
                      ) : (
                        <div>
                          <label className="fw-bold">Check-out</label>
                          <p className="m-0">
                            {currentBooking.exitTime == null
                              ? "No registrado"
                              : formatDate(
                                  currentBooking.exitTime,
                                  "dd/MM/yyyy HH:mm:ss"
                                )}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
}
