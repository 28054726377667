import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { EmptyDataList } from "../layout/AppLayout.js";

import BookingItem from "../bookings/BookingItem.js";

import Breadcrumb from "../shared/Breadcrumb.js";
import Spinner from "../shared/Spinner.js";

import React from "react";

function BookingsList({ bookings, showUser, showBuilding }) {
  if (showBuilding == true) {
    return (
      <React.Fragment>
        <div className="col-12 mt-3">
          <div
            className="row align-items-center text-muted text-uppercase"
            style={{ fontSize: ".9em" }}
          >
            <div className="col-3 text-center">
              <span className="p-2">Nombre</span>
            </div>
            <div className="col-1 text-center">
              <span className="p-2">Patente</span>
            </div>
            <div className="col-2 text-center">
              <span className="p-2">Estacionamiento</span>
            </div>
            <div className="col-2 text-center">
              <span className="p-2">Ubicación</span>
            </div>

            <div className="col-2 text-center">
              <span className="p-2">Hora inicio</span>
            </div>
            <div className="col-2 text-center">
              <span className="p-2">Hora fin</span>
            </div>
          </div>
        </div>
        {bookings.map((item) => (
          <BookingItem
            key={item.id}
            showUser={showUser}
            item={item}
            showBuilding={showBuilding}
          />
        ))}
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <div className="col-12 mt-3">
        <div
          className="row align-items-center text-muted text-uppercase"
          style={{ fontSize: ".9em" }}
        >
          <div className="col-3">
            <span className="p-2">Fecha</span>
          </div>

          <div className="col-3">
            <span className="p-2">Edificio</span>
          </div>

          <div className="col-3">
            <span className="p-2">Patente</span>
          </div>

          <div className="col-3">
            <span className="p-2">Estado</span>
          </div>
        </div>
      </div>
      {bookings.map((item) => (
        <BookingItem
          key={item.id}
          showUser={showUser}
          item={item}
          showBuilding={showBuilding}
        />
      ))}
    </React.Fragment>
  );
}

function PersonalBookingsList() {
  const params = useParams();

  const query = useQuery(`users/${params.UserId}/booking`);
  const bookings = (query.data && query.data.result && query.data.result) || [];

  return (
    <React.Fragment>
      {query.isLoading ? (
        <Spinner full center />
      ) : !bookings.length ? (
        <EmptyDataList message="No tienes reservas por asignar, confirmadas o rechazadas" />
      ) : (
        <BookingsList
          bookings={bookings}
          showBuilding={false}
          showUser={false}
        />
      )}
    </React.Fragment>
  );
}

export default function UserDetailPage() {
  const params = useParams();

  const query = useQuery(`users/${params.UserId}/booking`);

  return (
    <div>
      <Breadcrumb
        items={[
          { label: "Mis reservas", to: "/" },
          {
            label: `Reservas usuario`,
          },
        ]}
      />
      <PersonalBookingsList />
    </div>
  );
}
