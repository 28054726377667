import { useState, useContext, useEffect, useRef } from "react";
import { useMutation, useQuery } from "react-query";
import { Link, useSearchParams } from "react-router-dom";

import { AppContext } from "../../App.js";
import { useMutationClient } from "../hooks.js";
import Keycloak from "keycloak-js";

const SSO_CLIENT_ID = window["config"]["SSO_CLIENT_ID"];
const SSO_AUTH_URL = window["config"]["SSO_AUTH_URL"];
const SSO_REALM = window["config"]["SSO_REALM"];
const SSO_REDIRECT_URL = window["config"]["SSO_REDIRECT_URL"];

const LoginForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { actions } = useContext(AppContext);
  const mutationClient = useMutationClient();

  const mutation = useMutation((data) => mutationClient.post("login", data), {
    onSuccess: (data) => {
      if (data.status === 200) {
        actions.setToken(data.data.token);
      }
    },
  });

  let errorMessage;

  if (mutation.isError && mutation.error) {
    errorMessage = mutation.error.response.data.error.message;
  }

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        mutation.reset();
        mutation.mutate({ email, password });
      }}
    >
      <div className="mb-4">
        <label className="fw-bold mb-2">Correo electrónico</label>
        <input
          className="form-control form-control-lg"
          type="text"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          disabled={mutation.isLoading}
        />
      </div>

      <div className="mb-4">
        <label className="fw-bold mb-2">Contraseña</label>
        <input
          className="form-control form-control-lg"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          disabled={mutation.isLoading}
        />
      </div>

      {errorMessage ? (
        <div className="mb-3 text-center text-danger">{errorMessage}</div>
      ) : null}

      <div className="d-grid mb-2">
        <input
          type="submit"
          className="btn btn-primary btn-lg mb-3"
          value="Iniciar sesión"
          disabled={mutation.isLoading}
        />

        <Link to="/password/recover" className="text-center mb-2">
          Recuperar contraseña
        </Link>

        <Link to="/signup" className="text-center">
          Crear cuenta
        </Link>
      </div>
    </form>
  );
};

const SSOLogin = () => {
  const [loginUrl, setLoginUrl] = useState(null);
  const keycloackRef = useRef(null);

  useEffect(() => {
    if (!keycloackRef.current) {
      const keycloak = new Keycloak({
        url: SSO_AUTH_URL,
        realm: SSO_REALM,
        clientId: SSO_CLIENT_ID,
      });

      keycloak
        .init({ flow: "standard", responseMode: "query" })
        .then(function (authenticated) {
          console.log("authenticated: ", authenticated);
          console.log("userinfo", keycloak.userInfo);

          const url = keycloak.createLoginUrl({
            redirectUri: SSO_REDIRECT_URL,
          });
          setLoginUrl(url);

          if (authenticated) {
            console.log("userinfo", keycloak.userInfo);
            console.log("token", keycloak.token);
          }
        })
        .catch(function (e) {
          console.log(e);
        });

      keycloackRef.current = keycloak;

      console.log(keycloackRef.current);
    }
  }, []);

  return (
    <a
      href={loginUrl}
      className={`btn btn-primary btn-lg mx-auto ${loginUrl ? "" : "disabled"}`}
    >
      {loginUrl ? "Iniciar sesión con mi Cuenta Los Héroes" : "Cargando..."}
    </a>
  );
};

const CodeAuth = ({ code }) => {
  const mutationClient = useMutationClient();
  const { actions } = useContext(AppContext);

  const mutation = useMutation(
    () => mutationClient.post("auth/code", { code }),
    {
      onSuccess: (data) => {
        console.log(data);
        if (data.status === 200) {
          actions.setToken(data.data.token);
        }
      },
    }
  );

  let errorMessage;
  if (mutation.isError && mutation.error) {
    errorMessage = mutation.error.response.data.error.message;
  }

  if (mutation.isIdle) {
    mutation.mutate();
  }

  return (
    <div className="text-center">
      {!mutation.isError ? (
        <span className={`btn btn-primary btn-lg mx-auto disabled`}>
          Iniciando sesión...
        </span>
      ) : (
        <div className="p-2 bg-danger text-white rounded">
          Hubo un error al iniciar sesión
        </div>
      )}
    </div>
  );
};

export default function LoginPage() {
  const [searchParams] = useSearchParams();

  const ssoCode = searchParams.get("code");

  return (
    <div
      className="row d-flex justify-content-center"
      style={{ height: "100vh" }}
    >
      <div className="col-12 col-lg-4 mt-5">
        <div className="card shadow-sm mt-5">
          <div className="card-body">
            <div className="text-center mb-4">
              <img src="/Logo-LH.png" width="150" />
            </div>

            {ssoCode ? (
              <CodeAuth code={ssoCode} />
            ) : SSO_CLIENT_ID ? (
              <div className="text-center">
                <SSOLogin />
              </div>
            ) : (
              <>
                <h4 className="text-center mb-4">
                  Iniciar sesión <br />
                  Reserva de estacionamientos
                </h4>
                <LoginForm />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
