import { useEffect, useContext, useReducer, useMemo } from "react";
import { ActionsContext } from "./App.js";

import * as uuid from "uuid";

import { useQuery } from "react-query";

export const TOKEN_KEY = "CURRENT_TOKEN";

const INITIAL_STATE = {
  isLoggedIn: false,
  token: null,
  currentUser: null,
  notifications: {},
  scrollBottom: false,
};

//export function useCurrentUser(){
//const actions = useContext(ActionsContext)
//const userQuery = useQuery('users/me', {
//refetchOnWindowFocus: false,
//refetchOnMount: false
//})

//const currentUser = userQuery.data && userQuery.data.result
//const currentCompany = currentUser && currentUser.current_company

//const isLoading = useQuery.isLoading

//useEffect(() => {
//if(currentUser){
//actions.SetUser(currentUser)
//actions.SetCompany(currentUser.current_company)
//}
//}, [currentUser])

//return { currentUser, isLoading }
//}

export function useAppReducer() {
  let currentToken;

  try {
    currentToken = localStorage.getItem(TOKEN_KEY);
  } catch (e) {}

  const [state, dispatch] = useReducer(
    (prevState, action) => {
      switch (action.type) {
        case "SET_TOKEN":
          return {
            ...prevState,
            isLoggedIn: true,
            token: action.token,
          };

        case "SET_USER":
          return {
            ...prevState,
            currentUser: action.user,
          };

        case "ADD_NOTIFICATION":
          const id = uuid.v4();

          return {
            ...prevState,
            notifications: {
              ...prevState.notifications,
              [id]: {
                id,
                ...action.notification,
                date: new Date(),
              },
            },
          };

        case "REMOVE_NOTIFICATION":
          const items = { ...prevState.notifications };
          delete items[action.NotificationId];

          return {
            ...prevState,
            notifications: items,
          };

        case "SCROLL_CHANGE":
          return {
            ...prevState,
            scrollBottom: action.bottom,
          };

        default:
          return prevState;
      }
    },
    {
      ...INITIAL_STATE,
      isLoggedIn: !!currentToken,
      token: currentToken,
    }
  );

  const actions = useMemo(
    () => ({
      setToken: (token) => {
        localStorage.setItem(TOKEN_KEY, token);
        dispatch({ type: "SET_TOKEN", token });
      },

      setCurrentUser: (user) => {
        dispatch({ type: "SET_USER", user });
      },

      signOut: () => {
        localStorage.removeItem(TOKEN_KEY);
        window.location.reload();
      },

      AddNotification: (notification) => {
        dispatch({ type: "ADD_NOTIFICATION", notification });
      },

      DismissNotification: (NotificationId) => {
        dispatch({ type: "REMOVE_NOTIFICATION", NotificationId });
      },

      ChangeScroll: (scroll) => {
        dispatch({ type: "SCROLL_CHANGE", ...scroll });
      },
    }),
    []
  );

  return { state, actions };
}
