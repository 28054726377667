import { useState } from "react";
import { useMutation } from "react-query";
import { Link } from "react-router-dom";

import { useMutationClient } from "../hooks.js";

export default function PasswordRecoverPage() {
  const [email, setEmail] = useState("");

  const mutationClient = useMutationClient();

  const mutation = useMutation(
    (data) => mutationClient.post("password/recover", data),
    {
      onSuccess: (data) => {
        console.log(data);
      },
    }
  );

  let errorMessage;

  if (mutation.isError && mutation.error) {
    errorMessage = mutation.error.response.data.error.message;
  }

  return (
    <div
      className="row d-flex justify-content-center"
      style={{ height: "100vh" }}
    >
      <div className="col-12 col-lg-3 mt-5">
        <div className="card shadow-sm mt-5">
          <div className="card-body">
            <div className="text-center mb-4">
              <Link to="/">
                <img src="/Logo-LH.png" width="150" />
              </Link>
            </div>

            <h4 className="text-center mb-4">Recuperar contraseña</h4>

            {mutation.isSuccess ? (
              <p className="alert alert-success text-center mb-0">
                Se ha enviado un correo electrónico con las instrucciones para
                reestablecer su contraseña.
              </p>
            ) : (
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  mutation.reset();
                  mutation.mutate({ email });
                }}
              >
                <p className="alert alert-info">
                  Para recuperar contraseña, ingrese la dirección de correo
                  electrónico asociada a su cuenta. Le enviaremos un link con
                  las instrucciones para realizar el cambio de su contraseña
                </p>

                <div className="mb-4">
                  <label className="fw-bold mb-2">Correo electrónico</label>
                  <input
                    className="form-control form-control-lg"
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    disabled={mutation.isLoading}
                  />
                </div>

                {errorMessage ? (
                  <div className="mb-3 text-center text-danger">
                    {errorMessage}
                  </div>
                ) : null}

                <div className="d-grid mb-2">
                  <input
                    type="submit"
                    className="btn btn-primary btn-lg mb-3"
                    value="Enviar correo"
                    disabled={mutation.isLoading}
                  />

                  <Link to="/login" className="text-center mb-2">
                    Iniciar sesión
                  </Link>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
