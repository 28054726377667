import { useEffect } from "react";
import { useMutation } from "react-query";
import { Link, useSearchParams } from "react-router-dom";

import { useMutationClient } from "../hooks.js";
import Spinner from "../shared/Spinner.js";

export default function VerifyPage() {
  const [searchParams] = useSearchParams();

  const mutationClient = useMutationClient();
  const mutation = useMutation((data) =>
    mutationClient.post("signup/verify", data)
  );
  const token = searchParams.get("t");

  let errorMessage;

  if (mutation.isError && mutation.error) {
    errorMessage =
      (mutation.error.response &&
        mutation.error.response.data &&
        mutation.error.response.data.error &&
        mutation.error.response.data.error.message) ||
      null;
  }

  useEffect(() => {
    if (mutation.isIdle) {
      mutation.mutate({ token });
    }
  }, [token]);

  return (
    <div
      className="row d-flex justify-content-center"
      style={{ height: "100vh" }}
    >
      <div className="col-12 col-lg-4 my-5">
        <div className="card shadow-sm mt-5">
          <div className="card-body">
            <div className="text-center mb-4">
              <img src="/Logo-LH.png" width="150" />
            </div>

            {mutation.isLoading ? (
              <Spinner full center />
            ) : mutation.isSuccess ? (
              <div className="text-center">
                <p className="alert alert-success m-0">
                  <i
                    className="bi bi-check-circle"
                    style={{ fontSize: "4em" }}
                  ></i>
                  <br />
                  Su correo electrónico ha sido confirmado exitosamente. Ahora
                  puede iniciar sesión y realizar reservas a estacionamientos.
                  <br />
                  <br />
                  <Link to="/login" className="">
                    Ir a iniciar sesión
                  </Link>
                </p>
              </div>
            ) : errorMessage ? (
              <p className="alert alert-danger">{errorMessage}</p>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
