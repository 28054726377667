import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import { useState } from "react";
import { EmptyDataList } from "../layout/AppLayout.js";
import Breadcrumb from "../shared/Breadcrumb.js";
import BookingItem from "../bookings/BookingItem.js";
import Spinner from "../shared/Spinner.js";
import { formatISO, startOfDay } from "date-fns";
import { useMutationClient } from "../hooks.js";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { AppContext } from "../../App.js";
import DatePicker, { DateObject, Calendar } from "react-multi-date-picker";
import FileSaver from "file-saver";

const MySwal = withReactContent(Swal);

function BookingsList({ bookings, showUser, showBuilding, thirdPerson }) {
  if (showBuilding == true) {
    return (
      <React.Fragment>
        <div className="col-12 mt-3">
          <div
            className="row align-items-center text-muted text-uppercase"
            style={{ fontSize: ".9em" }}
          >
            <div className="col-3 text-center">
              <span className="p-2">Nombre</span>
            </div>
            <div className="col-1 text-center">
              <span className="p-2">Patente</span>
            </div>
            <div className="col-2 text-center">
              <span className="p-2">Estacionamiento</span>
            </div>
            <div className="col-2 text-center">
              <span className="p-2">Ubicación</span>
            </div>

            <div className="col-2 text-center">
              <span className="p-2">Hora inicio</span>
            </div>
            <div className="col-2 text-center">
              <span className="p-2">Hora fin</span>
            </div>
          </div>
        </div>
        {bookings.map((item) => (
          <BookingItem
            key={item.id}
            showUser={showUser}
            item={item}
            showBuilding={showBuilding}
            thirdPerson={thirdPerson}
          />
        ))}
      </React.Fragment>
    );
  }
  if (thirdPerson == true) {
    return (
      <React.Fragment>
        <div className="col-12 mt-3">
          <div
            className="row align-items-center text-muted text-uppercase"
            style={{ fontSize: ".9em" }}
          >
            <div className="col-3 text-center">
              <span className="p-2">Nombre</span>
            </div>
          </div>
        </div>
        {bookings.map((item) => (
          <BookingItem
            key={item.id}
            showUser={showUser}
            item={item}
            showBuilding={showBuilding}
            thirdPerson={thirdPerson}
          />
        ))}
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <div className="col-12 mt-3">
        <div
          className="row align-items-center text-muted text-uppercase"
          style={{ fontSize: ".9em" }}
        >
          <div className="col-3">
            <span className="p-2">Fecha</span>
          </div>

          <div className="col-3">
            <span className="p-2">Edificio</span>
          </div>

          <div className="col-3">
            <span className="p-2">Patente</span>
          </div>

          <div className="col-3">
            <span className="p-2">Estado</span>
          </div>
        </div>
      </div>
      {bookings.map((item) => (
        <BookingItem
          key={item.id}
          showUser={showUser}
          item={item}
          showBuilding={showBuilding}
          thirdPerson={thirdPerson}
        />
      ))}
    </React.Fragment>
  );
}

function BookingsListBuilding({ start_date, building, filter }) {
  var day;
  var month;
  var year;
  try {
    day = start_date.getDay();
  } catch (error) {
    day = start_date.day;
  }

  try {
    month = start_date.getMonth();
  } catch (error) {
    month = start_date.month;
  }

  try {
    year = start_date.getFullYear();
  } catch (error) {
    year = start_date.year;
  }

  const query = useQuery(
    `listBooking?day=${day}/${month}/${year}&json=true&subBuilding=${building}`
  );
  var bookings = (query.data && query.data.result && query.data.result) || [];
  var bookings = bookings.filter((item) => item.booking.length > 0);

  if (bookings.length < 1) {
    return <EmptyDataList message="No hay reservas para este edificio" />;
  }
  if (bookings[0] && bookings[0].booking.length == 0) {
    return <EmptyDataList message="No hay reservas para este edificio" />;
  }
  var bookingsTmp = [];
  for (var i = 0; i < bookings[0].booking.length; i++) {
    for (var j = 0; j < bookings[0].booking[i][1].length; j++) {
      bookingsTmp.push(bookings[0].booking[i][1][j]);
    }
  }

  return (
    <React.Fragment>
      {query.isLoading ? (
        <Spinner full center />
      ) : !bookings.length ? (
        <EmptyDataList message="No hay reservas por asignar, confirmadas o rechazadas" />
      ) : (
        <BookingsList
          bookings={bookingsTmp}
          showBuilding={true}
          thirdPerson={false}
        />
      )}
    </React.Fragment>
  );
}

function PersonalBookingsList({ start_date }) {
  const query = useQuery(`bookings?startTime=${start_date}`);
  const bookings =
    (query.data && query.data.result && query.data.result.bookings) || [];

  return (
    <React.Fragment>
      {query.isLoading ? (
        <Spinner full center />
      ) : !bookings.length ? (
        <EmptyDataList message="No tienes reservas por asignar, confirmadas o rechazadas" />
      ) : (
        <BookingsList
          bookings={bookings}
          showBuilding={false}
          thirdPerson={false}
        />
      )}
    </React.Fragment>
  );
}

function ThirdUserBookingsList({ start_date }) {
  const query = useQuery(
    `bookings?startTime=${start_date}&third_party_bookings=1`
  );
  const bookings =
    (query.data && query.data.result && query.data.result.bookings) || [];

  let users = bookings.map((item) => ({
    id: item.plate.user.id,
    name: item.plate.user.first_name + " " + item.plate.user.last_name,
  }));

  users = users.filter((obj, pos, arr) => {
    return arr.map((mapObj) => mapObj.id).indexOf(obj.id) == pos;
  });

  return (
    <React.Fragment>
      {query.isLoading ? (
        <Spinner full center />
      ) : !bookings.length ? (
        <EmptyDataList message="No tienes reservas de otros usuarios" />
      ) : (
        <BookingsList
          bookings={users}
          showUser={true}
          showBuilding={false}
          thirdPerson={true}
        />
      )}
    </React.Fragment>
  );
}

export default function HomePage() {
  const { state } = useContext(AppContext);
  const charge =
    (state &&
      state.currentUser &&
      state.currentUser.charge &&
      state.currentUser.charge.charge) ||
    "";
  const mutationClient = useMutationClient();
  const [filter, setFilter] = useState("");
  const [SelectMultiDay, setSelectMultiDay] = useState(new Date());
  const start_date = formatISO(startOfDay(new Date()));
  const donwloadList = useMutation(
    () =>
      mutationClient.get(
        `listBooking?day=${SelectMultiDay.day}/${SelectMultiDay.month}/${SelectMultiDay.year}&subBuilding=${building}`
      ),
    {
      onSuccess: (data) => {
        if (data.status === 200) {
          let blob = new Blob([data.data], {
            type: "application/pdf;charset=utf-8",
          });
          FileSaver.saveAs(blob, "Listado de estacionamiento.pdf");
        } else {
          MySwal.fire("Se ha producido un error, intentelo mas tarde");
        }
      },
    }
  );
  const buildingDivisionQuery = useQuery(`users/me/guard`);
  const buildingDivision =
    (buildingDivisionQuery &&
      buildingDivisionQuery.data &&
      buildingDivisionQuery.data.result &&
      buildingDivisionQuery.data.result.buildingDivision) ||
    [];
  const [building, setBuilding] = useState(0);

  return (
    <div>
      {charge == "Guardia" ? null : (
        <div className="row mt-3">
          <div className="d-flex justify-content-between">
            <Breadcrumb items={[{ label: "Mis reservas" }]} />

            <div className="d-flex justify-content-between">
              <div className="me-2">
                <Link className="btn btn-primary" to="bookings/new">
                  Nueva reserva
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}

      {charge == "Guardia" ? (
        buildingDivision.length == 0 ? (
          <EmptyDataList message="No tiene permisos para ver las reservas" />
        ) : (
          <div>
            <div className="d-flex justify-content-end">
              <button
                className="btn btn-primary"
                onClick={() => {
                  donwloadList.mutate();
                }}
              >
                Descarga
              </button>
            </div>
            <div className="col-12 mt-3 ">
              <div className="row ">
                <div className="col-5">
                  <input
                    type="text"
                    className="form-control form-control-lg"
                    placeholder="Buscar reserva"
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                    disabled={mutationClient.isLoading}
                  />
                </div>
                <div className="col-5">
                  <select
                    value={building}
                    className="form-control form-control-lg"
                    onChange={(e) => setBuilding(e.target.value)}
                    disabled={mutationClient.isLoading}
                  >
                    <option value="0">Seleccione un edificio</option>
                    {buildingDivision.map((item) => (
                      <option
                        key={item.buildingDivision.id}
                        value={item.buildingDivision.id}
                      >
                        {item.buildingDivision.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-2 align-self-center d-flex justify-content-center">
                  <DatePicker
                    style={{
                      width: "100%",
                      boxSizing: "border-box",
                      height: "28px",
                    }}
                    containerStyle={{
                      width: "50%",
                    }}
                    months={[
                      "Enero",
                      "Febrero",
                      "Marzo",
                      "Abril",
                      "Mayo",
                      "Junio",
                      "Julio",
                      "Agosto",
                      "Septiembre",
                      "Octubre",
                      "Noviembre",
                      "Diciembre",
                    ]}
                    weekDays={["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sa"]}
                    format=" DD/MM/YYYY "
                    formattingIgnoreList={["/", " "]}
                    calendarPosition="bottom-center"
                    value={SelectMultiDay}
                    onChange={setSelectMultiDay}
                    mapDays={({ date }) => {
                      let props = {};
                      let isSunday = [0].includes(date.weekDay.index);
                      if (isSunday) {
                        props.disabled = true;
                      }
                      return props;
                    }}
                    multiple={false}
                  />
                </div>
              </div>
            </div>
            <BookingsListBuilding
              start_date={SelectMultiDay}
              building={building}
              filter={filter}
            />
          </div>
        )
      ) : (
        <PersonalBookingsList start_date={start_date} />
      )}

      {state.currentUser && state.currentUser.third_party_bookings ? (
        <div className="mt-5">
          <Breadcrumb items={[{ label: "Reservas para otros usuarios" }]} />
          <ThirdUserBookingsList start_date={start_date} />
        </div>
      ) : null}
    </div>
  );
}
