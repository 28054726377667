import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { Link, useSearchParams } from "react-router-dom";

import { useMutationClient } from "../hooks.js";
import Spinner from "../shared/Spinner.js";

function useValidateToken() {
  const [searchParams] = useSearchParams();

  const token = searchParams.get("t");
  const query = useQuery(`password/reset?t=${token}`, {
    retry: false,
  });

  let error = null;

  if (query.isError && query.error) {
    error =
      (query.error &&
        query.error.response &&
        query.error.response.data &&
        query.error.response.data.error &&
        query.error.response.data.error.message) ||
      null;
  }

  return [query.isSuccess, query.isLoading, token, error];
}

export default function PassordResetPage() {
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [isValidToken, isValidatingToken, token, tokenError] =
    useValidateToken();

  const mutationClient = useMutationClient();

  const mutation = useMutation((data) =>
    mutationClient.post("password/reset", data)
  );

  let errorMessage;
  if (mutation.isError && mutation.error) {
    errorMessage = mutation.error.response.data.error.message;
  }

  return (
    <div
      className="row d-flex justify-content-center"
      style={{ height: "100vh" }}
    >
      <div className="col-12 col-lg-3 mt-5">
        <div className="card shadow-sm mt-5">
          <div className="card-body">
            <div className="text-center mb-4">
              <Link to="/">
                <img src="/Logo-LH.png" width="150" />
              </Link>
            </div>

            <h4 className="text-center mb-4">Reestablecer contraseña</h4>

            {isValidatingToken ? (
              <Spinner full center />
            ) : isValidToken ? (
              mutation.isSuccess ? (
                <p className="alert alert-success text-center mb-0">
                  Su contraseña ha sido reestablecida exitosamente
                  <br /> <br />
                  <Link to="/login">Iniciar sesión</Link>
                </p>
              ) : (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    mutation.reset();
                    mutation.mutate({
                      password,
                      passwordConfirmation,
                      token,
                    });
                  }}
                >
                  <div className="mb-4">
                    <label className="fw-bold mb-2">Contraseña</label>
                    <input
                      className="form-control form-control-lg"
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      disabled={mutation.isLoading}
                    />
                  </div>

                  <div className="mb-4">
                    <label className="fw-bold mb-2">Confirmar contraseña</label>
                    <input
                      className="form-control form-control-lg"
                      type="password"
                      value={passwordConfirmation}
                      onChange={(e) => setPasswordConfirmation(e.target.value)}
                      disabled={mutation.isLoading}
                    />
                  </div>

                  {errorMessage ? (
                    <div className="mb-3 text-center text-danger">
                      {errorMessage}
                    </div>
                  ) : null}

                  <div className="d-grid mb-2">
                    <input
                      type="submit"
                      className="btn btn-primary btn-lg mb-3"
                      value="Cambiar contraseña"
                      disabled={mutation.isLoading}
                    />
                  </div>
                </form>
              )
            ) : (
              <div>
                <p className="alert alert-danger text-center mb-0">
                  {tokenError}
                  <br /> <br />
                  <Link to="/password/recover">Reestablecer contraseña</Link>
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
